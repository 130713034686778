// @flow

import { map } from 'ramda'

import { generateIdFromTitle } from '../../../helpers'
import type { OverviewItem } from '../../../types'

const overview = [
  {
    title: 'Views',
    message: 'Exquisite Gulf Cottage with panoramic gulf-front view',
  },
  {
    title: 'Designer Decorated',
    message:
      'Designer decorated with wood ceilings and windows opening to the beautiful white sandy beach.',
  },
  {
    title: 'Room Configuration',
    message: '2 Bedroom (1K, 1Q-1T) / 2 Bath. Sleeper sofa in living room.',
  },
]

export const gulfOverview: OverviewItem[] = map(generateIdFromTitle, overview)

export default gulfOverview
