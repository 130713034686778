// @flow

import React from 'react'
import type { Node } from 'react'

import type { LegacyImage, OverviewItem } from '../../../types'
import { Layout } from '../../../components'

import ListingImageGroup from '../image-group'
import ListingOverview from '../overview'
import ListingWelcomeMessage from '../welcome-message'
import ListingHero from '../hero'
import ListingMainContent from '../main-content'
import ListingBook from '../book'

type CreateListingProps = {
  id: string,
  cottageName: string,
  featuredImageUrl: string,
  overview: OverviewItem[],
  welcomeMessage: string,
  images: LegacyImage[],
}

function CreateListing({
  id,
  cottageName,
  featuredImageUrl,
  overview,
  welcomeMessage,
  images,
}: CreateListingProps): Node {
  const renderCottageName = `${cottageName} Cottage`
  return (
    <Layout>
      <div id={id}>
        <ListingHero title={renderCottageName} imageUrl={featuredImageUrl} />
        <ListingMainContent>
          {overview && <ListingOverview data={overview} />}
          <ListingWelcomeMessage>{welcomeMessage}</ListingWelcomeMessage>
          <ListingImageGroup data={images} />
        </ListingMainContent>
        <ListingBook cottageName={cottageName} />
      </div>
    </Layout>
  )
}

export default CreateListing
