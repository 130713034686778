// @flow

import React from 'react'
import type { Element } from 'react'
import cx from 'classnames'
import { BodyText, Image } from '../../../components'
import type { LegacyImage } from '../../../types'

type Props = {
  data: LegacyImage[],
  className?: string,
}

function ImageGroup({ data, className }: Props): Element<'div'> {
  return (
    <div className={className}>
      {data.map((item) => {
        const { caption, image, id } = item
        const { url, alt } = image
        return (
          <div
            key={id}
            id={id}
            className={cx(
              'mb-8 bg-white text-grey-darkest rounded overflow-hidden p-6 shadow-lg',
              'md:max-w-md md:mx-auto leading-normal',
            )}
          >
            {url && <Image src={url} alt={alt} />}
            {caption && <BodyText className="mt-4">{caption}</BodyText>}
          </div>
        )
      })}
    </div>
  )
}
ImageGroup.defaultProps = {
  className: '',
}

export default ImageGroup
