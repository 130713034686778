// @flow

import * as React from 'react'
import { Heading2 } from '../../../components'

type Props = {
  children: React.Node,
}

function WelcomeMessage({ children }: Props): React.Element<'div'> {
  return (
    <div className="mt-24 mb-12">
      <Heading2>{children}</Heading2>
    </div>
  )
}

export default WelcomeMessage
