// @flow

import * as React from 'react'

type Props = {
  children: React.Node,
}

function MainContent({ children }: Props): React.Element<'div'> {
  return (
    <div className="bg-purple text-white relative">
      <div className="pt-12 pb-2 md:py-16 px-8">
        <div className="w-full max-w-lg mx-auto text-center">{children}</div>
      </div>
    </div>
  )
}

export default MainContent
