// @flow

import React from 'react'
import type { Node } from 'react'

import { graphql, useStaticQuery } from 'gatsby'
import { CreateListing } from '../../scenes/listing'

import { getFormattedData } from '../../helpers'
import { gulfOverview } from '../../data/cottages-content/gulf'

import type {
  CottagePageContent,
  LegacyImage,
  OverviewItem,
  Image,
} from '../../types'

type QueryResponse = {|
  gulfCottagePage: CottagePageContent,
|}

type GulfCottagePagePureProps = {
  data: {
    overview: OverviewItem[],
    cottageImages: LegacyImage[],
    heroImage: Image,
  },
}

function GulfCottagePagePure({
  data: { overview, cottageImages, heroImage },
}: GulfCottagePagePureProps) {
  return (
    <CreateListing
      id="gulf-cottage"
      cottageName="Gulf"
      featuredImageUrl={heroImage.src}
      overview={overview}
      welcomeMessage="Welcome to the Gulf Cottage"
      images={cottageImages}
    />
  )
}

function GulfCottagePage(): Node {
  const data: QueryResponse = useStaticQuery(graphql`
    query GulfCottagePageQuery {
      gulfCottagePage: contentfulCottagePage(title: { eq: "Gulf" }) {
        id
        title
        featuredImage {
          id
          title
          description
          image: resize(width: 1800, quality: 80) {
            src
          }
        }
        cottageImages {
          id
          description
          image: resize(width: 800, quality: 80) {
            src
          }
        }
      }
    }
  `)

  return (
    <GulfCottagePagePure
      data={{
        ...getFormattedData(data.gulfCottagePage),
        overview: gulfOverview,
      }}
    />
  )
}

export default GulfCottagePage
