// @flow

import type { Element } from 'react'
import React from 'react'
import cx from 'classnames'

import { Heading4 } from '../../../components/typography'
import type { OverviewItem } from '../../../types/cottage-page'

type Props = {
  data: OverviewItem[],
}

function CottageOverview({ data }: Props): Element<'div'> {
  return (
    <div className="bg-white leading-tight text-left rounded shadow text-black p-8 md:flex my-8">
      {data.map(({ id, title, message }, index) => (
        <div key={id} className={cx('mb-3 md:w-1/3', index !== 2 && 'md:pr-4')}>
          <Heading4 className="mb-2 text-grey-darkest">{title}</Heading4>
          <div className="text-grey-darker">{message}</div>
        </div>
      ))}
    </div>
  )
}

export default CottageOverview
