// @flow

import type { Node } from 'react'
import React from 'react'
import { Heading1, Hero } from '../../../components'

type Props = {
  imageUrl: string,
  title: string,
}

function ListingHero({ imageUrl, title }: Props): Node {
  return (
    <Hero backgroundImage={imageUrl}>
      <div className="flex items-center justify-center p-8 h-full">
        <div className="w-full max-w-lg mx-auto text-center">
          <Heading1 isTitle className="text-white text-4xl md:text-5xl">
            {title}
          </Heading1>
        </div>
      </div>
    </Hero>
  )
}

export default ListingHero
