// @flow
import * as React from 'react'

import { SectionTitle, BodyText, PillButtonLink } from '../../../components'

type Props = {
  cottageName?: string,
}

function Book({ cottageName }: Props): React.Element<'div'> {
  return (
    <div className="pt-12 pb-16 md:py-24 px-8">
      <div className="w-full max-w-lg mx-auto">
        <div className="md:w-3/5">
          <SectionTitle className="text-grey-darkest pb-4">
            Reach Out to Us
          </SectionTitle>
          <BodyText className="leading-normal pb-6 text-grey-darker">
            Find availability or information about the {cottageName} cottage at
            Cottages of Paradise Point today; where you’ll find solace in our
            relaxing Fort Myers Beach Cottages.
          </BodyText>
          <div className="inline-block">
            <PillButtonLink
              to="/contact-us"
              color="salmon"
              className="text-white"
            >
              Contact Us
            </PillButtonLink>
          </div>
        </div>
      </div>
    </div>
  )
}
Book.defaultProps = {
  cottageName: null,
}

export default Book
